<template>
  <div class="dash-pro-item mb-30 dashboard-widget">
    <div class="header">
      <h4 class="title">Resumo do agendamento</h4>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <CardLote />
      </div>
      <div class="col-12 col-md-6">
        <div class="card-content">
          <div class="row">
            <div class="col-12 py-5 text-center detalhe">
              <p>RETIRADA</p>
              <p>{{ getAgData | formatDataHora }} às {{ getAgHora }}</p>
              <p>{{ getAgLote.localEndereco }}</p>
            </div>

            <div class="col-12">
              <div class="alert alert-danger" role="alert" v-if="mensagemErro">
                {{ mensagemErro }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 text-center mt-2">
        <Button
          desc="Voltar"
          :width="45"
          :disabled="loading"
          tela="agendar"
          class="mr-2"
        ></Button>
        <Button
          desc="confirmar"
          :width="45"
          :click="confirmar"
          :loading="loading"
          :disabled="loading"
        ></Button>
      </div>
      <div class="col-12 col-md-6"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Button from './components/AgButton.vue';
import CardLote from './components/CardLote.vue';

export default {
  name: 'ResumoView',
  components: {
    Button,
    CardLote,
  },
  data: () => ({
    loading: false,
    mensagemErro: null,
  }),
  computed: {
    ...mapGetters(['getAgLote', 'getAgData', 'getUsuarioInfo', 'getAgHora']),
  },
  methods: {
    ...mapActions(['agendar', 'info']),
    ...mapMutations(['setAgTela', 'setAgAgendamentoId']),
    async confirmar() {
      this.loading = true;
      this.mensagemErro = null;
      const hora = this.getAgHora.split(':');

      const payload = {
        dataAgendamento: moment(this.getAgData)
          .set({ hour: hora[0], minute: hora[1], second: 0 })
          .utc(),
        loteId: this.getAgLote.loteId,
        usuarioId: this.getUsuarioInfo.usuarioId,
      };
      try {
        const resp = await this.agendar(payload);
        const { agendamentoId } = resp.data.data;
        this.loading = false;
        this.setAgAgendamentoId(agendamentoId);
        this.$nextTick(() => this.setAgTela('Comprovante'));
      } catch (error) {
        this.mensagemErro =
          error.response?.data.Message ||
          'Não foi possível efetuar o agendamento no momento.';
        this.loading = false;
      }
    },
  },
  filters: {
    formatDataHora(value) {
      return moment(value).utc().format('DD/MM/YYYY');
    },
  },
  mounted() {
    this.info();
  },
};
</script>

<style scoped>
.card-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
}

.detalhe p {
  font-size: 18px;
}

.detalhe p:nth-child(1) {
  font-size: 24px;
  color: #2baf49;
  font-weight: bolder;
}
</style>
