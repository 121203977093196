<template>
  <div class="dash-pro-item mb-30 dashboard-widget imprimir">
    <div class="text-center">
      <img :src="logo" width="220" class="mb-5" />
    </div>
    <div class="header">
      <h4 class="title">Comprovante de agendamento</h4>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-3">
            <span class="destaque">Protocolo: </span>
          </div>
          <div class="col-9">
            <span class="destaque">{{ getAgAgendamentoId }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <span>Leilão: </span>
          </div>
          <div class="col-9">
            <span>{{ getAgLote.leilao }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <span>Lote: </span>
          </div>
          <div class="col-9">
            <span>{{ getAgLote.numeroLote }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <span>Descrição: </span>
          </div>
          <div class="col-9">
            <span>{{ getAgLote.descricao }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <span>Local: </span>
          </div>
          <div class="col-9">
            <span>{{ getAgLote.localEndereco }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <span>Data: </span>
          </div>
          <div class="col-9">
            <span>{{ getAgData | formatDataHora }} às {{ getAgHora }}</span>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12">
            <div class="separador"></div>
            <p>Atenção!</p>
            <p>
              Verifique a documentação exigida no edital, parágrafo 'RETIRADA
              DOS LOTES'.
            </p>
          </div>
        </div>

        <div class="row mt-2 imprimir-btn">
          <div class="col-12 text-center mt-2">
            <Button
              desc="Voltar"
              :width="45"
              :disabled="loading"
              tela="lotes"
              class="mr-2"
            ></Button>
            <Button desc="Imprimir" :width="45" :click="imprimir"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from './components/AgButton.vue';
import moment from 'moment';
export default {
  name: 'ComprovanteView',
  components: {
    Button,
  },
  data() {
    return {
      logo: process.env.VUE_APP_LOGO_PATH,
    };
  },
  computed: {
    ...mapGetters([
      'getAgLote',
      'getAgData',
      'getAgHora',
      'getAgAgendamentoId',
    ]),
  },
  methods: {
    imprimir() {
      window.print();
    },
  },
  filters: {
    formatDataHora(value) {
      return moment(value).utc().format('DD/MM/YYYY');
    },
  },
};
</script>

<style>
.imprimir span {
  font-size: 14px;
}
span.destaque {
  font-weight: bolder;
  font-size: 16px;
}

.separador {
  border-bottom: 1px dashed #ccc;
  margin-bottom: 15px;
}
@media print {
  @page {
    margin: 0px;
  }
  html,
  body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  body * {
    visibility: hidden;
  }

  .title {
    text-align: center;
    padding-bottom: 20px;
  }

  .globalClass_a045,
  .globalClass_a045 * {
    display: none;
  }

  .imprimir-btn {
    display: none;
  }

  header {
    display: none;
  }
  .hero-section {
    display: none;
  }

  .imprimir,
  .imprimir * {
    visibility: visible;
  }

  .imprimir {
    margin: 0;
    padding: 0;
    --position: absolute;
    --left: 0;
    --top: 0;
  }
}
</style>
