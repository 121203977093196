<template>
  <div class="col-lg-8">
    <component v-bind:is="getAgTela"></component>
    <!-- <Lotes v-if="getAgTela == 'Lotes'" />
    <Agendar v-if="getAgTela == 'agendar'" />
    <Resumo v-if="getAgTela == 'Resumo'" />
    <Comprovante v-if="getAgTela == 'Comprovante'" /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Lotes from "./Lotes.vue";
import Agendar from "./Agendar.vue";
import Resumo from "./Resumo.vue";
import Comprovante from "./Comprovante.vue";
import DetalheAgendamento from "./DetalheAgendamento";
export default {
  components: {
    Lotes,
    Agendar,
    Resumo,
    Comprovante,
    DetalheAgendamento,
  },
  computed: {
    ...mapGetters(["getAgLoteId", "getAgTela"]),
    current() {
      return this.getAgAgendar ? "Agendar" : "Lotes";
    },
  },
  methods: {},
};
</script>

<style>
</style>