<template>
  <div class="card-content" v-if="loteComp">
    <div class="d-flex justify-content-between align-items-center mb-1 card-main">
      <h6 class="titulo">LOTE {{ loteComp.numeroLote }}</h6>
      <div>
        <p
          class="descricao badge"
          :class="loteComp.statusPagamento == 'PAGO' ? 'badge-success' : !loteComp.faturaToken ? 'badge-info' : 'badge-warning'"
        >
          {{ loteComp.statusPagamento == 'PAGO' ? 'PAGO' : loteComp.faturaToken ? 'PENDENTE' : 'PROCESSANDO' }}
        </p>
      </div>
    </div>
    <div class="card-img">
      <img :src="loteComp && loteComp.foto.arquivo.url" :title="loteComp && loteComp.descricao" />
    </div>

    <div class="row card-main">
      <div class="col-12 text-left">
        <p class="descricao">{{ loteComp.descricao }}</p>
      </div>
      <!-- <div class="col-12">
        <p class="descricao">LEILÃO: {{ loteComp.leilao }}</p>
      </div> -->

      <!-- <div class="col-3 text-left">
        <p class="titulo">LOTE</p>
        <p class="valor">{{ loteComp.numeroLote }}</p>
      </div> -->
      <div class="col-12 text-left">
        <p class="titulo">
          VALOR TOTAL:
          <b>{{
            loteComp.info
              ? loteComp.info.valorTotal
              : 0
                | currency('R$', 2, {
                  spaceBetweenAmountAndSymbol: true,
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
          }}</b>
        </p>
      </div>
      <div class="col-12 mt-2" v-if="btn">
        <button
          class="card-btn"
          :disabled="(loteComp.statusPagamento != 'PAGO' && !loteComp.faturaToken) || (loteComp.statusPagamento == 'PAGO' && !agendamentoLiberado)"
          :class="
            loteComp.statusPagamento == 'PAGO' && loteComp.statusAgendamento == 'AGENDADO'
              ? 'detalhe-btn'
              : loteComp.statusPagamento == 'PAGO'
              ? 'agendar-btn'
              : 'pagar-btn'
          "
          @click="agendar"
        >
          {{
            loteComp.statusPagamento == 'PAGO' && loteComp.statusAgendamento == 'AGENDADO'
              ? 'VER AGENDAMENTO'
              : loteComp.statusPagamento == 'PAGO'
              ? 'AGENDAR RETIRADA'
              : 'VER FATURA'
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';
import http from '../../../../service/http/http';
export default {
  props: {
    lote: {
      type: Object,
    },
    btn: {
      type: Boolean,
      default: false,
    },
    tela: {
      type: String,
    },
  },
  data: () => ({
    agendamentoLiberado: null,
  }),
  name: 'CardLote',
  computed: {
    ...mapGetters(['getAgLote']),
    loteComp() {
      return this.lote ? this.lote : this.getAgLote;
    },
  },
  methods: {
    ...mapMutations(['setAgLoteId', 'setAgLote', 'setAgTela']),
    agendar() {
      if (this.loteComp.statusPagamento == 'PAGO' && this.loteComp.statusAgendamento == 'AGENDADO') {
        this.setAgLote(this.lote);
        this.$nextTick(() => this.setAgTela('DetalheAgendamento'));
        return;
      }

      if (this.loteComp.statusPagamento == 'PAGO') {
        this.setAgLote(this.lote);
        this.$nextTick(() => this.setAgTela('Agendar'));
        return;
      }

      if (this.loteComp.faturaToken) {
        window.open(`../fatura/${this.loteComp.faturaToken}`, '_blank').focus();
      }
    },
    async getDataAgendamento() {
      this.dataInicioAgendamento;
      const resp = await http({
        url: `leilao/${this.loteComp.leilaoId}`,
        method: 'get',
      });

      this.dataInicioAgendamento = resp.data?.data?.dataInicioAgendamento;

      this.agendamentoLiberado = moment().isBetween(
        moment(resp.data?.data?.dataInicioAgendamento),
        moment(resp.data?.data?.dataFimAgendamento),
        'day',
        '[]'
      );
    },
  },
  filters: {
    formataData(value) {
      return moment(value).utc().format('DD/MM/YYYY');
    },
  },
  created() {
    this.getDataAgendamento();
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.card-btn {
  border: none;
  height: 40px;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.1rem;
  font-size: 0.6rem;
  outline: 0;
  opacity: 1;
  text-transform: uppercase;
}

.agendar-btn {
  background-color: #4cb848;
}
.detalhe-btn {
  background-color: #0783ae;
}
.pagar-btn {
  background-color: #fec32b;
}
.card-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 10px;
  box-shadow: 0px 5px 5px #0000003b;
}

.card-img img {
  object-fit: cover;
  overflow: hidden;
  aspect-ratio: 4/2;
  object-position: center;
  width: 100%;
  border-radius: 5px;
}

.card-main .titulo {
  font-size: 13px;
  color: #52606d;
}

.card-main .valor {
  font-size: 18px;
  font-weight: bolder;
}

.card-main .descricao {
  font-size: 12px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}

button:disabled,
button[disabled] {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}
</style>
