<template>
  <button
    class="ag-btn"
    :disabled="disabled"
    :style="'width:' + width + '%'"
    @click="nav"
  >
    <span>{{ desc }}</span>
    <span
      v-if="loading"
      class="spinner-border spinner-border-sm ml-2"
      role="status"
      aria-hidden="true"
    ></span>
  </button>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
    tela: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 100,
    },
    click: {
      type: Function,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations(["setAgTela"]),
    nav() {
      if (this.tela) {
        this.setAgTela(this.tela);
        return;
      }
      this.click();
    },
  },
};
</script>

<style scoped>
.ag-btn {
  background-color: #fec32b;
  border: none;
  height: 50px;
  border-radius: 25px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.1rem;
  font-size: 0.8rem;
  outline: 0;
  opacity: 1;
  text-transform: uppercase;
}
.ag-btn:nth-child(1) {
  background-color: #4cb848;
}

.ag-btn:hover {
  opacity: 0.9;
}

.ag-btn:active {
  transform: translateY(1px);
}

.ag-btn:disabled {
  background-color: #cccccc;
}

@media screen and (max-width: 992px) {
  .ag-btn {
    background-color: #fec32b;
    border: none;
    width: 45%;
  }
}

@media screen and (max-width: 460px) {
  .ag-btn {
    background-color: #fec32b;
    border: none;
    width: 100%;
  }
}
</style>